import { makeApiRequest } from 'actions/api';
import RequestService from 'services/RequestService';

export const SHOPPING_LIST_SET_SERVINGS = 'SHOPPING_LIST_SET_SERVINGS';

export const SHOPPING_LIST_ADD_MEAL = 'SHOPPING_LIST_ADD_MEAL';
export const SHOPPING_LIST_REMOVE_MEAL = 'SHOPPING_LIST_REMOVE_MEAL';
export const SHOPPING_LIST_SELECT_INGREDIENT = 'SHOPPING_LIST_SELECT_INGREDIENT';
export const SHOPPING_LIST_UNSELECT_INGREDIENT = 'SHOPPING_LIST_UNSELECT_INGREDIENT';

export const GET_SHOPPING_LIST_MEAL = 'GET_SHOPPING_LIST_MEAL';
export const GET_SHOPPING_LIST_MEAL_REQUEST = 'GET_SHOPPING_LIST_MEAL_REQUEST';
export const GET_SHOPPING_LIST_MEAL_RESPONSE = 'GET_SHOPPING_LIST_MEAL_RESPONSE';
export const GET_SHOPPING_LIST_MEAL_ERROR = 'GET_SHOPPING_LIST_MEAL_ERROR';

export const addMeal = mealId => ({
  type: SHOPPING_LIST_ADD_MEAL,
  mealId,
});

export const addMealToShoppingList = mealId => async dispatch => {
  dispatch(addMeal(mealId));
  return dispatch(getShoppingListMeal(mealId));
};

export const removeMealFromShoppingList = mealId => ({
  type: SHOPPING_LIST_REMOVE_MEAL,
  mealId,
});

export const unselectIngredientForShoppingList = name => ({
  type: SHOPPING_LIST_UNSELECT_INGREDIENT,
  name,
});

export const reselectIngredientForShoppingList = name => ({
  type: SHOPPING_LIST_SELECT_INGREDIENT,
  name,
});

export const setServings = (mealId, servings) => ({
  type: SHOPPING_LIST_SET_SERVINGS,
  mealId,
  servings,
});

export const getShoppingListMeal = mealId =>
  makeApiRequest({
    method: RequestService.getShoppingListMeal,
    params: { mealId },
    requestType: GET_SHOPPING_LIST_MEAL,
  });

import RequestService from 'services/RequestService';
import { makeApiRequest } from 'actions/api';

export const UPDATE_NSO_REVIEW_COMMENT = 'UPDATE_NSO_REVIEW_COMMENT';
export const UPDATE_NSO_REVIEW_COMMENT_REQUEST = 'UPDATE_NSO_REVIEW_COMMENT_REQUEST';
export const UPDATE_NSO_REVIEW_COMMENT_RESPONSE = 'UPDATE_NSO_REVIEW_COMMENT_RESPONSE';
export const UPDATE_NSO_REVIEW_COMMENT_ERROR = 'UPDATE_NSO_REVIEW_COMMENT_ERROR';

export const CLOSE_NSO_REVIEW_COMMENT = 'CLOSE_NSO_REVIEW_COMMENT';

export const handleCloseNsoReviewComment = () => ({
  type: CLOSE_NSO_REVIEW_COMMENT,
});

export const updateNsoReviewComment = (reviewId, comment) =>
  makeApiRequest({
    requestType: UPDATE_NSO_REVIEW_COMMENT,
    method: RequestService.updateNsoReview,
    params: { reviewId, review: { comment } },
  });

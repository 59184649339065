import omit from 'lodash/omit';
import keyBy from 'lodash/keyBy';

import {
  GET_NSO_PENDING_REVIEWS_REQUEST,
  GET_NSO_PENDING_REVIEWS_RESPONSE,
  ADD_NSO_REVIEW_RESPONSE,
  NSO_REVIEW_CHANGE,
  UPDATE_NSO_REVIEW_ERROR,
  UPDATE_NSO_REVIEW_REQUEST,
  UPDATE_NSO_REVIEW_RESPONSE,
} from 'actions/sendABox/reviews';

const transformReviewsResponse = response => keyBy(response.reviews, 'meal.id');

const transformUpdateReviewResponse = response => omit(response, ['meal', 'menu', 'rating']);

export default (state = {}, action) => {
  const { mealId, params, review, response, type } = action;

  switch (type) {
    case GET_NSO_PENDING_REVIEWS_REQUEST:
      return {
        ...state,
        isError: false,
      };
    case GET_NSO_PENDING_REVIEWS_RESPONSE:
      return {
        ...state,
        ...transformReviewsResponse(response),
      };
    case NSO_REVIEW_CHANGE:
      return {
        ...state,
        [mealId]: {
          ...state[mealId],
          pendingChanges: review,
        },
      };
    case UPDATE_NSO_REVIEW_REQUEST:
      return {
        ...state,
        isError: false,
        [params.mealId]: {
          ...state[params.mealId],
          isLoading: true,
        },
      };
    case UPDATE_NSO_REVIEW_ERROR:
      return {
        ...state,
        isError: true,
        [params.mealId]: {
          ...omit(state[params.mealId], 'pendingChanges'),
          isLoading: false,
        },
      };
    case ADD_NSO_REVIEW_RESPONSE:
      return {
        ...state,
        [response.meal.id]: response,
      };
    case UPDATE_NSO_REVIEW_RESPONSE:
      return {
        ...state,
        [response.meal.id]: transformUpdateReviewResponse(response),
      };
    default:
      return state;
  }
};

export const selectReview = (state, mealId) => state[mealId];
